// If you don't want to use TypeScript you can delete this file!
import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { PageProps, Link, graphql, useStaticQuery } from "gatsby";
import HomePage from "../components/homepage";

const Index: React.FC<PageProps<{}>> = ({ path }) => {
  const [rates, setRates] = useState({
    USD: 0,
  });
  const conv = useCallback(
    (value) => {
      if (!rates.USD) {
        return (0.01).toFixed(3);
      }
      return (value * rates.USD).toFixed(3);
    },
    [rates]
  );
  const dollarValue = useMemo(() => conv(0.2), [conv]);
  const dollarService = useMemo(() => conv(25), [conv]);
  useEffect(() => {
    fetch(`https://api.exchangeratesapi.io/latest?base=ZAR`)
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setRates(data.rates);
      });
  }, []);

  return (
    <HomePage price={dollarValue} serviceCharge={+dollarService} sym="$" />
  );
};

export default Index;
